import React from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

//Styled Components
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";

//Components
import cursor from "../assets/images/ui/cursor.png";

// import {
//     useGlobalStateContext,
//     useGlobalDispatchContext,
// } from "../context/globalContext";
import genshinFontWoff from "../assets/font/thekima.woff";
import genshinFontWoff2 from "../assets/font/thekima.woff2";

const GlobalStyle = createGlobalStyle`
${normalize}
* {
  text-decoration: none;
  margin: 0;
  padding: 0;
  /* cursor: none; */
  outline: none;
  box-sizing: border-box;
}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
}
body {
  /* font-family: 'Averia Libre', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    overscroll-behavior: none;
    cursor: url(${cursor}), auto;
    button {
        cursor: url(${cursor}), auto;
    }
    background: #5cbad0;

  @font-face {
        font-family: "tt_skip-e85w";
        src: url(${genshinFontWoff2}) format("woff2"),
            url(${genshinFontWoff}) format("woff");
        font-weight: normal;
        font-style: normal;
    }
  font-family: "tt_skip-e85w";
}   
`;

//!SPECIAL NOTES FOR APP
//!Update version through npm version
//!Banner renewal: The json are to be ordered by 5 stars first following 4 stars.
//!Banner renewal: Must add permanent banner file like wanderlust-xiao.json due to banner button usually being changed.

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);
    // const darkTheme = {};
    const lightTheme = {
        boxShadow: "0px 0px 6px 2px rgba(40, 40, 40, 0.2);",
        boxLight: "0px 0px 7px 5px rgba(160, 175, 190, 0.8);",
        background: "#EDF0F5",
        backgroundColor: "#E4E0D5",
        text: "#464C5E",
        textSecondary: "#B5A18C",
        textShop: "#d7be90",
        warning: "#FF5F40",
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyle />
            <main>{children}</main>
        </ThemeProvider>
    );
};

export default Layout;

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
